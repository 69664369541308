import React from 'react';
import Layout from "../components/layout";

const Success = () => {
  return(
    <Layout>
      Your payment is Successfull.
    </Layout>
  );
};

export default Success;
